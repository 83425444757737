<template>
  <div class="footer-warpper flex">
    <div class="footer flex-center flex-column">
      <div>Copyright © 东莞市嵩湖湾网络科技有限公司保留所有权利</div>
      <div>广东省东莞市东城街道红川路9号创新大厦203室</div>
      <div>
        <a href="http://beian.miit.gov.cn/" target="_blank"
        >粤ICP备19116151号</a
        >
      </div>

      <div>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006721" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="../../assets/images/icon.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备
            44190002006721号</p></a>
      </div>
    </div>
    <div class="footer-qr flex felx-1">
      <div class="left-text">
        <h2>咨询电话</h2>
        <div>0769-88499773</div>
        <div>17728906805</div>
        <div>gujunjie@songhuwan.com</div>
      </div>

      <div>
        <img alt="" src="../../assets/images/qr-code.jpg"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped>
.footer-warpper {
  padding: 40px 15%;
  justify-content: space-between;
  /* flex-direction: column; */
  background-color: #f4f4f4;
}

.footer-warpper .footer {
  width: 70%;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-warpper .footer div {
  margin-bottom: 20px;
}

.footer-warpper .footer div a {
  text-decoration: none;
  color: #7c8894;
}

.footer-warpper .footer-qr {
  align-items: flex-end;
}

.footer-warpper .footer-qr h2 {
  margin-bottom: 15px;
}

.footer-warpper .footer-qr div {
  margin-bottom: 10px;
}

.footer-warpper .footer-qr img {
  width: 128px;
  height: 128px;
  margin-top: 10px;
  position: relative;
  right: -7px;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.felx-1 {
  flex: 1;
}
.left-text{
  text-align: right;
  margin-right:30px ;
}
</style>
