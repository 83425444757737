<template>
  <div class="header-warpper flex">
    <img src="../../assets/images/logo.png" alt="" />
  </div>
</template>

<script lang="ts" setup></script>

<style scoped>
.header-warpper {
  position: absolute;
  top: 20px;
  width: 99%;
  min-height: 100px;
  z-index: 9999;
  justify-content: flex-start;
  align-items: center;
}
.header-warpper img {
  width: 220px;
}
</style>
