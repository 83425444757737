<template>
  <div class="index-container">
    <header>
      <my-header></my-header>
      <el-carousel
        class="carousel"
        trigger="click"
        height="643px"
        arrow="never"
      >
        <el-carousel-item v-for="(item, n) in carouselList" :key="n">
          <div
            class="carousel-content"
            :style="{ backgroundImage: `url(${item.bg})` }"
          ></div>
        </el-carousel-item>
      </el-carousel>
    </header>
    <main>
      <div class="about-us flex-center flex-column">
        <h1>关于我们</h1>
        <p>
          东莞市嵩湖湾网络科技有限公司创立于2019年8月，创始人与其核心技术团队曾服务过多家上市公司完成其信息化平台建设。嵩湖湾科技以互联网平台软件开发为基础，顺应国家号召逐步进入互联网+系统集成、集团信息化平台建设、物联网智能控制、人工智能数据分析等领域，为企业提供全方位的信息化解决方案。切实解决企业痛点，提高所服务企业的运行效率，实现降本增效。
        </p>
      </div>
      <div class="about-us slogan flex-center flex-column">
        <h1>服务理念</h1>
        <p>专注于提供互联网平台软件开发方案与物联网系统整体解决方案。</p>
      </div>
      <div class="about-us examples">
        <h1>经典案例</h1>
        <div class="flex examples-list">
          <div
            class="examples-item flex-center flex-column"
            v-for="(item, index) in examples"
            :key="index"
          >
            <h3>{{ item.title }}</h3>
            <span>{{ item.content }}</span>
          </div>
        </div>
      </div>
    </main>
    <!-- footer -->
    <div class="footer">
      <my-footer></my-footer>
    </div>
  </div>
</template>

<script setup>
import MyFooter from "./components/MyFooter/index.vue";
import MyHeader from "./components/MyHeader/index.vue";

const carouselList = [
  { bg: "../../assets/images/1.png" },
  { bg: "../../assets/images/2.png" },
  { bg: "../../assets/images/3.png" },
];

const examples = [
  {
    title: "社区团购分销电商平台",
    content:
      "由深圳三刀木科技有限公司自主运营的社区分销电商平台，平台上线2个月已吸引了6000分销人员，每日销售单量超过20000单。",
  },
  {
    title: "快马快充新能源平台",
    content:
      "由广东华融新能源有限公司运营，我司全流程开发的新能源充电平台，支持新能源汽车充电计费、电动自行车充电计费、停车费减免、优惠券活动、储值卡等各类运营模块。",
  },
  {
    title: "康佳优品电商平台",
    content:
      "由深圳康佳电子科技有限公司运营，我司全流程开发的小程序电商平台，支持三级分销管理，提供优惠券、满减、秒杀、拼团、返佣等各类常见玩法。",
  },
  {
    title: "汇金楼展馆小程序",
    content:
      "汇金楼展览馆自主运营，我司自主研发的展览馆小程序，提供展馆线上看展、看展预约，展馆活动报名等功能。",
  },
  {
    title: "黄金小镇园区管理平台",
    content:
      "黄金小镇自主运营，我司自主研发的园区商家管理平台，展示园区活动、园区商家信息，同时打通商家支付平台，与园区会员系统。",
  },
  {
    title: "城城联同城信息平台",
    content:
      "由深圳市城城联网络科技有限公司运营，我司全流程开发的同城信息平台，提供租房、电信宽带等生活服务类信息平台。",
  },
];
</script>

<style scoped>
.index-container {
  margin: 0 auto;
  min-height: 100vh;
  color: #7c8894;
}
h1 {
  color: #000;
}
.carousel >>> .el-carousel__item .carousel-content {
  padding-top: 100px;
  color: #475669;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.about-us {
  padding: 50px 15%;
}
.about-us h1 {
  margin-bottom: 20px;
  text-align: center;
}
.slogan {
  background-image: url("./assets/images/hero-scene.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 450px;
  justify-content: flex-start;
}
.examples {
  padding: 50px 12.5%;
}
.examples h1 {
  margin-bottom: 40px;
}
.examples .examples-list {
  flex-wrap: wrap;
}
.examples .examples-item {
  width: 33.3%;
  padding: 0 3%;
  margin-bottom: 50px;
  justify-content: flex-start;
  line-height: 1.7;
}
.examples .examples-item h3 {
  margin-bottom: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.examples-list {
  display: flex;
  justify-content: center;
}
</style>
